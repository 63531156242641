//export const WEBENGAGE_LICENSE_CODE:string = "in~aa131691";// staging code
export const WEBENGAGE_LICENSE_CODE:string = "in~76aa1d8"
export const WEBENGAGE_EVENTS = {
  Category_Selected:'Category Selected',
  Subcategory_Selected:'Subcategory Selected',
  Banners_Clicked:'Banners Clicked',
  Explore_Space_Clicked:'Explore Space Clicked',
  Flexible_Membership_Clicked:'Flexible Membership Clicked',
  Footer_Clicked:'Footer Clicked',
  Social_Media_Clicked:'Social Media Clicked',
  Search_By_Location:'Search By Location',
  City_Selected:'City Selected',
  Visit_The_Places:'Visit The Places',
  Request_A_Quote:'Request A Quote',
  Similar_Workspaces_Visited:'Similar Workspaces Visited',
  Contact_Us:'Contact Us',
  Become_a_Partners:'Become a Partners',
  Partner_with_Us:'Partner with Us',
  Managed_Office_Spaces_Form:'Managed Office Spaces Form',
  Get_Started:'Get Started',
  Basic_Details:'Basic_Details',
  Plan_Selected:'Plan Selected',
  Coworking_membership_plans:'Coworking membership plans',
  Fixed_Membership:'Fixed Membership',
  Space_Selected:'Space Selected',
  Request_Generated:'Request Generated',
  User_Info:'User Info.',
  Start_a_Free_Trial:'Start a Free Trial',
  Free_Trial_Purchases:'Free Trial Purchases',
  Schedule_a_Demo:'Schedule a Demo',
  Watch_Video:'Watch Video',
  FAQs_Read:'FAQs Read',
  Explore_Plans:'Explore Plans',
  Inventories_Pricing:'Inventories & Pricing',
  Amenities_Covid_19_precautions:'Amenities & Covid-19 precautions'

}

export enum SpaceTypeCategory {
  PlatinumPlus = "platinum+",
  Platinum = "platinum",
  Standard = "standard",
  Silver = "silver",
  Gold = "gold",
}
export const metaCities = [
  "gurgaon",
  "bengaluru",
  "mumbai",
  "pune",
  "hyderabad",
  "noida",
  "kolkata",
  "delhi-nct",
  "coimbatore",
  "dubai",
  "indore",
  "lucknow",
  "north-goa",
  "goa",
  "surat",
  "faridabad",
  "chennai",
  "mysuru-mysore",
  "pondicherry",
];

export const banners = [

  {
    src: "assets/images/banners/festive.jpg",
    href: "https://stylework.app.link/website",
    alt: "festive offer",
  },
  {
    src: "assets/images/banners/wallet.jpg",
    href: "https://corp.stylework.city/auth/login/signup",
    alt: "FlexBoard",
  },
  {
    src: "assets/images/banners/months.jpg",
    href: "#",
    alt: "offer",
  },
  {
    src: "assets/images/banners/meetin-room.jpg",
    href: "#",
    alt: "Meeting Room",
  },
  {
    src: "assets/images/banners/multilocation.jpg",
    href: "https://stylework.app.link/website",
    alt: "multilocation membership",
  },

  {
    src: "assets/images/banners/day-pass.jpeg",
    href: "https://stylework.app.link/website",
    alt: "coworking day pass offer",
  },
  {
    src: "assets/images/banners/fixed-membership.jpg",
    href: "/membership/fixed-membership",
    alt: "coworking day pass offer",
  },

  {
    src: "assets/images/banners/invite-earn.jpeg",
    href: "https://stylework.app.link/website",
    alt: "Invite and Earn",
  },
  // {
  //   src: "assets/images/banners/mysoho.png",
  //   href: "https://mysoho.co/?utm_source=Stylework_Website&utm_medium=Header_Banner&utm_campaign=Mysoho_FSS",
  //   alt: "My Soho",
  // },
];

export const SpaceTypeImages = {
  [SpaceTypeCategory.Platinum]: "assets/icons/platinum-badge-small.svg",
  [SpaceTypeCategory.Gold]: "assets/icons/gold-badge-small.svg",
  [SpaceTypeCategory.Silver]: "assets/icons/silver-badge-small.svg",
  [SpaceTypeCategory.Standard]: "assets/icons/standard-badge-small.svg",
  [SpaceTypeCategory.PlatinumPlus]:
    "assets/icons/platinum-plus-badge-small.svg",
};
export const CityWiseKeywords = `Coworking Space, Shared Office Space, Coworking Space, Cafe With Wifi, Office Space For Rent, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room`;
export const SpaceDetailsKeywords = `Coworking spaces, working space, coworking, office space, coworking space near me, meeting rooms, private cabins, desks, open desk, office day pass, rent office for a day`;
