/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Security } from 'src/app/Helper/security';
import { EnvConfigurationService } from 'src/app/Helper/environment-configuration.service';
import { state } from '@angular/animations';
import {
  PopularLocations,
  Logo,
  Testimonial,
  Articles,
  Amenity,
  SafetyStandard,
  SeatCategory,
  BlogsResponse,
  BlogDetailsResponse,
} from '../../interface';
import { CountryCodes } from '../../interface/timestamp';
import { Cities, City, State } from '../../interface/cities';
import { EventCoupon, EventPackage, EventReq, EventResponse } from '../../interface/event';
import { PaymentConfirmation } from '../razorpay.service';
import { NewsletterSubscription } from 'src/app/components/main-footer/main-footer.component';


enum PARTNERTYPE {
  clients,
  media,
}

export interface QueryParams {
  lat: number;
  long: number;
  count: number;
  page: number;
  id?: string;
  name?: string;
  areaSlug?: string;
  citySlug?: string;
  popular?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  config = this.envConfigService.configSubject$;
  popularCity$: Observable<PopularLocations[]>;
  private step = new BehaviorSubject(1);
  step$ = this.step.asObservable();

  private popularSubject = new BehaviorSubject<PopularLocations[]>([]);

  constructor(
    private envConfigService: EnvConfigurationService,
    private http: HttpClient,
    private security: Security,
    private router: Router,
  ) {
    this.popularCity$ = this.popularSubject.asObservable();
  }
  changeStep(data: number): void {
    this.step.next(data);
  }
  setSteps(): void {
    const url = this.router.routerState.snapshot.url.split('/')[2];
    switch (url){
    case 'Enter-basic-details': this.changeStep(1); break;
    case 'Select-a-slot': this.changeStep(2); break;
    }
  }
  managedSpace(): void {
    const url = this.router.routerState.snapshot.url.split('/')[4];
    switch (url){
    case 'location-details': this.changeStep(2); break;
    case 'confirm-space': this.changeStep(3); break;
    case 'detail-summary': this.changeStep(4);break;
    default: this.changeStep(1);
    }
  }
  career(): void {
    const url = this.router.routerState.snapshot.url.split('/')[4].split('?')[0];
    switch (url){
    case 'your-detail': this.changeStep(2); break;
    case 'confirm-application': this.changeStep(3); break;
    case 'final': this.changeStep(4);break;
    default: this.changeStep(1);
    }
  }

  // HttpClient API get() method => Fetch Blogs list
  getBlogs(page: number=1, count: number=6): Observable<BlogsResponse> {
    return this.http
      .get<BlogsResponse>(`${this.config.value.apiUrl}blogs?page=${page}&count=${count}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getBlogById(blogId: string): Observable<BlogDetailsResponse>{
    return this.http
      .get<BlogDetailsResponse>(`${this.config.value.apiUrl}blogs/${blogId}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getBlogDetailsByTitle(blogTitle: string): Observable<BlogDetailsResponse>{
    return this.http
      .get<BlogDetailsResponse>(`${this.config.value.apiUrl}blogs/title/${blogTitle}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getSuggestedBlogs(blogId: string): Observable<BlogDetailsResponse[]>{
    return this.http
      .get<BlogDetailsResponse[]>(`${this.config.value.apiUrl}blogs/${blogId}/suggestions`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCountryCodes(): Observable<CountryCodes[]> {
    return this.http
      .get<CountryCodes[]>(`${this.config.value.apiUrl}country-codes`)
      .pipe(catchError(this.handleError));
  }
  getV2CountryCodes(): Observable<CountryCodes[]> {
    return this.http
      .get<CountryCodes[]>(`${this.config.value.apiUrl}v2/country-codes`)
      .pipe(catchError(this.handleError));
  }

  postMessage(data:NewsletterSubscription): Observable<void> {
    return this.http
      .post<void>(`${this.config.value.apiUrl}newsletter/subscribe`, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API get() method => Fetch Basic Amenties list
  getbasicAmenities(): Observable<Amenity[]> {
    return this.http
      .get<Amenity[]>(`${this.config.value.apiUrl}amenities`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API get() method => Fetch Amenties Safety list
  getSafety(): Observable<SafetyStandard[]> {
    return this.http
      .get<SafetyStandard[]>(`${this.config.value.apiUrl}safety-standards`)
      .pipe(retry(1), catchError(this.handleError));
  }

  //   HttpClient API get() method => Fetch Amenties Desk type list
  getdeskType(): Observable<SeatCategory[]> {
    return this.http
      .get<SeatCategory[]>(`${this.config.value.apiUrl}categories`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API get() method => Fetch Article list
  getArticle(): Observable<Articles[]> {
    return this.http
      .get<Articles[]>(`${this.config.value.apiUrl}articles`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API get() method => Fetch testimonials list
  getTestimonials(): Observable<Testimonial[]> {
    return this.http
      .get<Testimonial[]>(`${this.config.value.apiUrl}testimonials/${PARTNERTYPE.clients}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // HttpClient API get() method => Fetch Company Logo list
  getLogo(): Observable<Logo[]> {
    return this.http
      .get<Logo[]>(`${this.config.value.apiUrl}associates?type=${PARTNERTYPE.media}`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getPopularCities(): Observable<PopularLocations[]> {
    return this.http
      .get<PopularLocations[]>(`${this.config.value.apiUrl}popular-locations`)
      .pipe(
        map((data: PopularLocations[]) => {
          localStorage.setItem('popularLocations', this.security.encryptData(data));
          this.popularSubject.next(data);
          return data;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }


  // HttpClient API get() method => Fetch cities list
  getCities(): Observable<{cityId: string; city: Cities}[]> {
    return this.http
      .get<{cityId: string; city: Cities}[]>(`${this.config.value.apiUrl}v2/spaces/cities`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCitieByStateId(stateId: string): Observable<City[]> {
    return this.http
      .get<City[]>(`${this.config.value.apiUrl}/states/${stateId}/cities`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getStates(): Observable<State[]>{
    return this.http
      .get<State[]>(`${this.config.value.apiUrl}states`)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error): ReturnType<typeof throwError> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getRazorPayKEy(): string {
    return this.config.value.razorPaySecretKeyId;
  }

  getEventPackages():Observable<EventPackage[]>{
    return this.http.get<EventPackage[]>(`${this.config.value.apiUrl}event-packages`)
  }


  getEventPackagesById(id:string):Observable<EventPackage>{
    return this.http.get<EventPackage>(`${this.config.value.apiUrl}event-packages/${id}`)
  }

  verifyCoupon(data:EventCoupon):Observable<{finalAmount: number}>{
    return this.http.post<{finalAmount: number}>(`${this.config.value.apiUrl}verify-event-coupon`,data)
  }

  getOrderId(data:EventReq):Observable<EventResponse>{
    return this.http.post<EventResponse>(`${this.config.value.apiUrl}get-order-id`,data)
  }

  verifyPayment(data:PaymentConfirmation,id:string):Observable<void>{
    return this.http.patch<void>(`${this.config.value.apiUrl}update-payment-status/${id}`,data)
  }

}
